// src/pages/UserManagement.tsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  TextField,
} from '@mui/material';
// import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Edit, Delete, Add } from '@mui/icons-material';
import axios from 'axios';
import { apiRequest } from 'utils/apiRequest';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import AddUserDialog from './createUser';
import EditUserDialog from './editUser';

// import axios from '../services/mockApi';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [formData, setFormData] = useState({ name: '', email: '', role: '2' });

  const handleGetUsers =async ()=>{
    const endpoint = `/api/v1/users`;
    console.log(endpoint);
    const token = localStorage.getItem('authToken');
    try {
      const response = await apiRequest('GET', endpoint, null, null, {
        Authorization: `Bearer ${token}`,
        accept: 'application/json',
      });

      setUsers(response?.data);
      setLoading(false); 
    } catch (error) {
      console.error('Error fetching company details:', error);
     
    }
  }
  // Fetch users
  useEffect(() => {
    handleGetUsers();
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Add User
  const handleAddUser =async (data) => {
    setLoading(true)
   
    const endpoint = `/api/v1/users`;
    console.log(endpoint);
    const token = localStorage.getItem('authToken');

      try {
        const response = await apiRequest('POST', endpoint, data, null, {
          Authorization: `Bearer ${token}`,
          accept: 'application/json',
        });
  
        setUsers([...users, response]);
        setAddDialogOpen(false);
        setLoading(false)
        setFormData({ name: '', email: '', role: '2' });
      } catch (error) {
        alert('Failed to add user.')
        setLoading(false) 
      }
  };

  // Edit User
  const handleEditUser =async (data) => {
    setLoading(true)
   
    const endpoint = `/api/v1/users/${selectedUser.id}`;
    
    const token = localStorage.getItem('authToken');

      try {
        const response = await apiRequest('PATCH', endpoint, data, null, {
          Authorization: `Bearer ${token}`,
          accept: 'application/json',
        });
        setUsers(users.map((user) => (user.id === selectedUser.id ? response : user)));
        setEditDialogOpen(false);
        setLoading(false);
      }
        catch(error){ alert('Failed to edit user.')
          setLoading(false);
         
        };
  };

  // Delete User
  const handleDeleteUser = () => {
    axios
      .delete(`/users/${selectedUser.id}`)
      .then(() => {
        setUsers(users.filter((user) => user.id !== selectedUser.id));
        setDeleteDialogOpen(false);
      })
      .catch(() => alert('Failed to delete user.'));
  };

  // Open edit dialog
  const openEditDialog = (user) => {
    setSelectedUser(user);
    setFormData({ name: user.name, email: user.email, role: user.role });
    setEditDialogOpen(true);
  };

  // Open delete dialog
  const openDeleteDialog = (user) => {
    setSelectedUser(user);
    setDeleteDialogOpen(true);
  };
  const fetchUserDetails = async (userId) => {
    console.log(userId);
    const endpoint = `/api/v1/users/${userId}`;
    const token = localStorage.getItem('authToken');
    try {
      const response =  apiRequest('GET', endpoint, null, null, {
        Authorization: `Bearer ${token}`,
        accept: 'application/json',
      });
      return response;
    } catch (error) {
      console.error('Error fetching user details:', error);
      throw new Error('Unable to fetch user details.'); // Handle error appropriately
    }
  };




  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Ensures it occupies the full viewport height
        }}
      >
        <CircularProgress />
      </div>
    );

  
  return (
    <DashboardLayout>
    <DashboardNavbar />
  <Paper>
    <Box p={3}>
      <h1>User Management</h1>
      <Button
        variant="contained"
        startIcon={<Add />}
        onClick={() => setAddDialogOpen(true)}
        sx={{ mb: 2 }}
      >
        Add User
      </Button>

      <TableContainer component={Paper}>
        <Table>
        <TableHead fullwidth sx={{ display: 'contents' }}>
            <TableRow>
             
              <TableCell>Email</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Role</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
             
                <TableCell>{user?.email}</TableCell>
                <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                <TableCell>{`Role ${user.role.id}`}</TableCell>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() => openEditDialog(user)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => openDeleteDialog(user)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
       <AddUserDialog
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onSubmit={handleAddUser}
      />

    
      <EditUserDialog
        open={editDialogOpen}
        onClose={()=>setEditDialogOpen(false)}
        onSubmit={handleEditUser}
        userId={selectedUser?.id}
        getUserById={fetchUserDetails}
      />

       

      {/* Delete Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          Are you sure you want to delete &quot;{selectedUser?.name}&quot;?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" color="error" onClick={handleDeleteUser}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    </Paper>
    </DashboardLayout>
    
  );
};

export default UserManagement;
