import React, { useState, useEffect } from 'react';
import {
  TextField,
  Grid,
  Button,
  MenuItem,
  Select,
  FormControl,
  FormLabel,
  Typography,
  Box,
  NativeSelect,
} from '@mui/material';
import ImageUpload from './imageUpload';
import GPSCoordinatesSelector from './GPSCoordinatesSelector';
import LocationInput from './NewMap';
import { apiRequest } from 'utils/apiRequest';

// eslint-disable-next-line react/prop-types
const ShopEditForm = ({ setOpen, shopId }) => {
  const [formData, setFormData] = useState({
    shopName: '',
    ownerName: '',
    contactPhone: '',
    contactEmail: '',
    fax: '',
    businessRegistrationNumber: '',
    openingHours: '',
    address: '',
    gpsCoordinates: '',
    region: '',
    typeOfBusiness: '',
    businessSize: '',
    productType: '',
    dailyTurnover: '',
    storageCapacity: '',
    deliveryTime: '',
    deliveryFrequency: '',
    paymentMethod: '',
    contractTerms: '',
    bankDetails: '',
    creditTerms: '',
    vatNumber: '',
    loyaltyAgreements: '',
    notes: '',
    businessLicense: null,
    taxDocument: null,
    proofOfAddress: null,
  });

  const [uploadedFiles, setUploadedFiles] = useState({
    businessLicenseId: { id: null, path: null },
    taxDocumentId: { id: null, path: null },
    proofOfAddressId: { id: null, path: null },
  });

  useEffect(() => {
    // Fetch the existing shop data using the shopId
    const fetchShopData = async () => {
      const token = localStorage.getItem('authToken');
      try {
        const response = await apiRequest('GET', `api/v1/zenbil-s-hop-companies/${shopId}`, null, null, {
          Authorization: `Bearer ${token}`,
        }); 
        setFormData(response);
        setUploadedFiles({
          businessLicenseId: { id: response.data.businessLicenseId, path: response.data.businessLicense },
          taxDocumentId: { id: response.data.proofOfAddress, path: response.data.taxDocument },
          proofOfAddressId: { id: response.data.proofOfAddress, path: response.data.proofOfAddress },
        });
      } catch (error) {
        console.error('Error fetching shop data:', error);
      }
    };

    if (shopId) {
      fetchShopData();
    }
    console.log(shopId);
  }, [shopId]);

  const handleFileUpload = (field, fileInfo) => {
    setUploadedFiles({
      ...uploadedFiles,
      [field]: {
        id: fileInfo.id,
        path: fileInfo.path,
      },
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const finalData = {
      ...formData,
      businessLicense: uploadedFiles.businessLicenseId.path,
      taxDocument: uploadedFiles.taxDocumentId.path,
      proofOfAddress: uploadedFiles.proofOfAddressId.path,
    };
    const token = localStorage.getItem("authToken");
    // axios.patch('https://zembil-backend.elnetech.com/api/v1/zenbil-s-hop-companies/6729cf68014e337b1ccfd36c', {
    //   ...finalData
    // }, {
    //   headers: {
    //     'accept': 'application/json',
    //     'Authorization': `Bearer ${token}`,
    //   }
    // })
    // .then(response => {
    //   console.log(response.data);
    // })
    // .catch(error => {
    //   console.error(error);
    // });
     try {
    //   // Use PUT or PATCH to update the existing company data
      await apiRequest('PATCH', `/api/v1/zenbil-s-hop-companies/${shopId}`, finalData, null, {
        Authorization: `Bearer ${token}`,
      });

       setOpen(false);
       alert('Company updated successfully');
     } catch (error) {
       console.error('Error updating company:', error);
     }
  };

  const regions = [
    "Addis Ababa", "Afar", "Amhara", "Oromia", "Somali", "Tigray", 
    "Benishangul-Gumuz", "Gambela", "SNNPR", "Dire Dawa", "Harari"
  ];

  const groupBoxStyles = {
    border: '1px solid #ccc',
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '20px',
  };

  const groupLabelStyles = {
    position: 'relative',
    top: '-16px',
    left: '16px',
    backgroundColor: '#fff',
    padding: '0 8px',
    fontWeight: 'bold',
  };

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Edit Shop Information
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
        
        <Box sx={groupBoxStyles}>
          <Typography sx={groupLabelStyles}>Basic Information</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Shop Name</FormLabel>
                <TextField
                  placeholder="Enter shop name"
                  name="shopName"
                  value={formData.shopName}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel>Owner/Manager’s Name</FormLabel>
                <TextField
                  placeholder="Enter owner or manager's name"
                  name="ownerName"
                  value={formData.ownerName}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel>Phone</FormLabel>
                <TextField
                  placeholder="Enter phone number"
                  name="contactPhone"
                  value={formData.contactPhone}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel>Email</FormLabel>
                <TextField
                  placeholder="Enter email"
                  name="contactEmail"
                  value={formData.contactEmail}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel>Fax</FormLabel>
                <TextField
                  placeholder="Enter fax (if any)"
                  name="fax"
                  value={formData.fax}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Business Registration Number</FormLabel>
                <TextField
                  placeholder="Enter business registration number"
                  name="businessRegistrationNumber"
                  value={formData.businessRegistrationNumber}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Opening Hours</FormLabel>
                <TextField
                  placeholder="e.g., 9:00 AM - 6:00 PM"
                  name="openingHours"
                  value={formData.openingHours}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
            <Box sx={groupBoxStyles}>
              <Typography sx={groupLabelStyles}>Location Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Physical Address</FormLabel>
                    <TextField
                      placeholder="Enter address"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>GPS Coordinates (Optional)</FormLabel>
                    {/* <GPSCoordinatesSelector
                      initialCoordinates={formData.gpsCoordinates}
                      onCoordinatesSelect={handleGPSCoordinatesSelect}
                    /> */}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Region</FormLabel>
                    <NativeSelect
                      value={formData.region}
                      onChange={handleInputChange}
                      defaultValue='Afar'
                      inputProps={{
                        name: 'region',
                        id: 'uncontrolled-native',
                      }}
                    >
                      {regions.map(region => (
                        <option key={region} value={region}>{region}</option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={groupBoxStyles}>
              <Typography sx={groupLabelStyles}>Shop Profile</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Type of Business</FormLabel>
                    <NativeSelect
                      name="typeOfBusiness"
                      value={formData.typeOfBusiness}
                      onChange={handleInputChange}
                    >
                      
                      <option value="Shop">Shop</option>
                      <option value="Supermarket">Supermarket</option>
                      <option value="Wholesale">Wholesale</option>
                      <option value="Pharmacy">Pharmacy</option>
                      <option value="Cafe">Cafe</option>
                    </NativeSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Business Size</FormLabel>
                    <TextField
                      placeholder="Enter business size"
                      name="businessSize"
                      value={formData.businessSize}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Daily Turnover</FormLabel>
                    <TextField
                      placeholder="Enter daily turnover"
                      name="dailyTurnover"
                      value={formData.dailyTurnover}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Storage Capacity</FormLabel>
                    <TextField
                      placeholder="Enter storage capacity"
                      name="storageCapacity"
                      value={formData.storageCapacity}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={groupBoxStyles}>
              <Typography sx={groupLabelStyles}>Distribution Preferences</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Preferred Delivery Time</FormLabel>
                    <TextField
                      placeholder="Enter preferred delivery time"
                      name="deliveryTime"
                      value={formData.deliveryTime}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Delivery Frequency</FormLabel>
                    <TextField
                      placeholder="Enter delivery frequency"
                      name="deliveryFrequency"
                      value={formData.deliveryFrequency}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Payment Method</FormLabel>
                    <TextField
                      placeholder="Enter payment method"
                      name="paymentMethod"
                      value={formData.paymentMethod}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Supply Contract Terms</FormLabel>
                    <TextField
                      placeholder="Enter contract terms"
                      name="contractTerms"
                      value={formData.contractTerms}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={groupBoxStyles}>
              <Typography sx={groupLabelStyles}>Financial Information</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Bank Account Details</FormLabel>
                    <TextField
                      placeholder="Enter bank details"
                      name="bankDetails"
                      value={formData.bankDetails}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Credit Terms</FormLabel>
                    <TextField
                      placeholder="Enter credit terms"
                      name="creditTerms"
                      value={formData.creditTerms}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>TIN Number</FormLabel>
                    <TextField
                      placeholder="Enter VAT number"
                      name="vatNumber"
                      value={formData.vatNumber}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* 6. Additional Data */}
          <Grid item xs={12}>
            <Box sx={groupBoxStyles}>
              <Typography sx={groupLabelStyles}>Additional Data</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Loyalty or Partnership Agreements</FormLabel>
                    <TextField
                      placeholder="Enter loyalty or partnership agreements"
                      name="loyaltyAgreements"
                      value={formData.loyaltyAgreements}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Notes</FormLabel>
                    <TextField
                      placeholder="Enter any additional notes"
                      name="notes"
                      value={formData.notes}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {/* For document uploads, handle conditional rendering */}
          <Grid item xs={12}>
            <ImageUpload
              label="Upload Business License"
              name='businessLicenseId'
              onUpload={handleFileUpload}
              defaultValue={formData.businessLicense}
              initialFile={uploadedFiles.businessLicenseId.path}
            />
          </Grid>
          <Grid item xs={12}>
            <ImageUpload
              label="Upload Tax Document"
              name='taxDocumentId'
              onUpload={handleFileUpload}
              defaultValue={formData.taxDocument}
              initialFile={uploadedFiles.taxDocumentId.path}
            />
          </Grid>
          <Grid item xs={12}>
            <ImageUpload 
              label="Upload Proof of Address Document" 
              name='proofOfAddressId'
              onUpload={handleFileUpload}
              defaultValue={formData.proofOfAddress}
              initialFile={uploadedFiles.proofOfAddressId.path}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ShopEditForm;
