/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Default marker icon for leaflet (fixes missing icon issue)
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

// GPS Coordinates Component using Leaflet map
const GPSCoordinatesSelector = ({ onCoordinatesSelect, initialCoordinates }) => {
  const [position, setPosition] = useState(initialCoordinates || [51.505, -0.09]); // Default to some location if no initial coordinates
  const [mapInitialized, setMapInitialized] = useState(false);

  // Get user's current location using the browser's Geolocation API
  useEffect(() => {
    if (!mapInitialized) {
      navigator.geolocation.getCurrentPosition((position) => {
        setPosition([position.coords.latitude, position.coords.longitude]);
        setMapInitialized(true);
      });
    }
  }, [mapInitialized]);

  // Function to handle dragging the marker to update position
  const LocationMarker = () => {
    useMapEvents({
      dragend(event) {
        const { lat, lng } = event.target.getLatLng();
        setPosition([lat, lng]);
        onCoordinatesSelect(`${lat}, ${lng}`); // Pass the selected coordinates as a string
      },
    });
    return position === null ? null : <Marker position={position} draggable />;
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Select Location
      </Typography>
      <MapContainer
        center={position}
        zoom={13}
        style={{ height: '300px', width: '100%' }}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <LocationMarker />
      </MapContainer>
    </Box>
  );
};

export default GPSCoordinatesSelector;
