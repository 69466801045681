import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
const sampleCompanies = [
  {
    id: "1",
    shopName: "Shop One",
    ownerName: "John Doe",
    businessRegistrationNumber: "REG123456",
    typeOfBusiness: "Retail",
    region: "Region A",
    contactEmail: "john@example.com",
    contactPhone: "+251 911 234 567",
  },
  {
    id: "2",
    shopName: "Shop Two",
    ownerName: "Jane Smith",
    businessRegistrationNumber: "REG654321",
    typeOfBusiness: "Wholesale",
    region: "Region B",
    contactEmail: "jane@example.com",
    contactPhone: "+251 922 345 678",
  },
  {
    id: "3",
    shopName: "Shop Three",
    ownerName: "Alice Johnson",
    businessRegistrationNumber: "REG987654",
    typeOfBusiness: "Service",
    region: "Region C",
    contactEmail: "alice@example.com",
    contactPhone: "+251 933 456 789",
  },
];

const YourTableComponent = () => {
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          
          <TableRow>
            <TableCell>Shop Name</TableCell>
            <TableCell>Owner/Manager</TableCell>
            <TableCell>Contact Info</TableCell>
            <TableCell>Actions</TableCell>
 
          </TableRow>
        </TableHead>
        <TableBody>
          {sampleCompanies.map((company) => (
            <TableRow
            hover
            key={company.id}
            onClick={() => handleRowClick(company.id)} // Navigate to details page
            style={{ cursor: 'pointer' }}
           >
              <TableCell>{company.shopName}</TableCell>
              <TableCell>{company.ownerName}</TableCell>
              <TableCell>{company.contactPhone}</TableCell>
              <TableCell>
                <IconButton color="primary" onClick={(e) => e.stopPropagation()}>
                  <Edit />
                </IconButton>
                <IconButton
                  color="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(company.id);
                  }}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default YourTableComponent;
