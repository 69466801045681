import React, { useState } from 'react';
import { Grid, TextField, MenuItem, Button, FormControl, InputLabel, Select, Typography, Checkbox, FormControlLabel } from '@mui/material';
import ImageUpload from './imageUpload';
// import { DatePicker } from '@mui/lab';
import axios from 'axios';
const ElsabiLoanForm = () => {
  const [formData, setFormData] = useState({
    govID: '',
    dateOfBirth: null,
    gender: '',
    photograph: null,
    fullName: '',
    motherFullName: '',
    phoneNumber: '',
    email: '',
    address: '',
    maritalStatus: '',
    employmentDetails: '',
    incomeSource: '',
    verificationDocuments: '',
    monthlyIncome: '',
    monthlyExpenses: '',
    businessRegistration: '',
    financialStatements: '',
    loanPurpose: '',
    collateral: '',
    collateralValue: '',
    coSigner: false,
    hasInsurance: false,
    understandsLoanTerms: false,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFileChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.files[0] });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, dateOfBirth: date });
  };

  const handleSubmit = async (event) => {
    
    event.preventDefault();
    // Process form data here
    console.log(formData);
    const token = localStorage.getItem("authToken");
    const form = {
      incomeSource: formData.incomeSource,
      employmentDetails: formData.employmentDetails,
      maritalStatus: formData.maritalStatus,
      address: formData.address,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      motherFullName: formData.motherFullName,
      fullName: formData.fullName,
      photograph: formData.photograph,
      gender: formData.gender,
      dateOfBirth: formData.dateOfBirth,
      govID: formData.govID,
    };

    try {
      const response = await axios.post(
        'https://zembil-backend.elnetech.com/api/v1/elsabi-loans',
        form,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        }
      );
  
      console.log('Response:', response.data);
      alert('Loan registered successfully');
    } catch (error) {
      console.error('Error registering company:', error);
    }
  };


  const handleFileUpload = (field, fileInfo) => {
    setUploadedFiles({
      ...uploadedFiles,
      [field]: {
        id: fileInfo.id,
        path: fileInfo.path,
      },
    });
  };
  return (
    <form onSubmit={handleSubmit} style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <Typography variant="h5" gutterBottom>Elsabi Loan Application</Typography>

      {/* Section 1: Personal Identification */}
      <Typography variant="h6" gutterBottom>Personal Identification</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Government-Issued ID"
            name="govID"
            placeholder="Enter Government-Issued ID"
            value={formData.govID}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: { color: 'gray', fontSize: '0.9rem' },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Date of Birth"
            name="dateOfBirth"
            placeholder="YYYY-MM-DD"
            type="date"
            value={formData.dateOfBirth}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: { color: 'gray', fontSize: '0.9rem' },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Gender</InputLabel>
            <Select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              displayEmpty
            >
              <MenuItem value="" disabled>Select Gender</MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Business License ID"
            name="businessLicenseId"
            placeholder="Enter Business License ID"
            value={formData.businessLicenseId}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: { color: 'gray', fontSize: '0.9rem' },
            }}
          />
        </Grid>
      </Grid>

      {/* Section 2: Client Background Information */}
      <Typography variant="h6" gutterBottom>Client Background Information</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Full Name"
            name="fullName"
            placeholder="Enter Full Name"
            value={formData.fullName}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ style: { color: 'gray', fontSize: '0.9rem' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Mother's Full Name"
            name="motherFullName"
            placeholder="Enter Mother's Full Name"
            value={formData.motherFullName}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ style: { color: 'gray', fontSize: '0.9rem' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Phone Number"
            name="phoneNumber"
            placeholder="Enter Phone Number"
            value={formData.phoneNumber}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ style: { color: 'gray', fontSize: '0.9rem' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Email"
            name="email"
            placeholder="Enter Email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ style: { color: 'gray', fontSize: '0.9rem' } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address"
            name="address"
            placeholder="Enter Address"
            value={formData.address}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ style: { color: 'gray', fontSize: '0.9rem' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Marital Status</InputLabel>
            <Select
              name="maritalStatus"
              value={formData.maritalStatus}
              onChange={handleChange}
              displayEmpty
            >
              <MenuItem value="" disabled>Select Marital Status</MenuItem>
              <MenuItem value="single">Single</MenuItem>
              <MenuItem value="married">Married</MenuItem>
              <MenuItem value="divorced">Divorced</MenuItem>
              <MenuItem value="widowed">Widowed</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Section 3: Financial Details */}
      <Typography variant="h6" gutterBottom>Financial Details</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Monthly Income"
            name="monthlyIncome"
            placeholder="Enter Monthly Income"
            value={formData.monthlyIncome}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ style: { color: 'gray', fontSize: '0.9rem' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Monthly Expenses"
            name="monthlyExpenses"
            placeholder="Enter Monthly Expenses"
            value={formData.monthlyExpenses}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ style: { color: 'gray', fontSize: '0.9rem' } }}
          />
        </Grid>
      </Grid>

      {/* Section 4: Loan and Collateral Information */}
      <Typography variant="h6" gutterBottom>Loan and Collateral Information</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Loan Purpose"
            name="loanPurpose"
            placeholder="Enter Loan Purpose"
            value={formData.loanPurpose}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ style: { color: 'gray', fontSize: '0.9rem' } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Collateral"
            name="collateral"
            placeholder="Describe Collateral"
            value={formData.collateral}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ style: { color: 'gray', fontSize: '0.9rem' } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Collateral Value"
            name="collateralValue"
            placeholder="Enter Collateral Value"
            value={formData.collateralValue}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ style: { color: 'gray', fontSize: '0.9rem' } }}
          />
        </Grid>
      </Grid>

      {/* Terms and Conditions */}
      <Typography variant="h6" gutterBottom>Agreement and Acknowledgment</Typography>
      <FormControlLabel
        control={<Checkbox checked={formData.coSigner} onChange={handleChange} name="coSigner" />}
        label="I have a co-signer for this loan."
      />
      <FormControlLabel
        control={<Checkbox checked={formData.hasInsurance} onChange={handleChange} name="hasInsurance" />}
        label="I have insurance for the collateral provided."
      />
      <FormControlLabel
        control={<Checkbox checked={formData.understandsLoanTerms} onChange={handleChange} name="understandsLoanTerms" />}
        label="I understand and agree to the loan terms and conditions."
      />

      <Button variant="contained" color="primary" type="submit" style={{ marginTop: '20px' }}>
        Submit Application
      </Button>
    </form>
  
  );
};

export default ElsabiLoanForm;
