import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  NativeSelect,
  CircularProgress,
} from "@mui/material";
import ImageUpload from "./imageUpload"; // Ensure this supports file preview
import { apiRequest } from "utils/apiRequest";

const ShopRegistrationForm = ({ initialData, setOpen }) => {
  const [formData, setFormData] = useState({
    shopName: "",
    ownerName: "",
    contactPhone: "",
    contactEmail: "",
    businessRegistrationNumber: "",
    openingHours: "",
    address: "",
    region: "",
    typeOfBusiness: "",
    creditLimit: "100,000 ETB", // Default value
    tinNumber: "",
    loyaltyAgreement: false,
    notes: "",
    businessLicense: null,
    nationalId: null,
  });

  const [loading, setLoading] = useState(false);
 
 
  const [uploadedFiles, setUploadedFiles] = useState({
    businessLicense: { id: null, path: null },
    nationalId: { id: null, path: null },
  });

  const regions = [
    "Addis Ababa",
    "Afar",
    "Amhara",
    "Oromia",
    "Somali",
    "Tigray",
    "Benishangul-Gumuz",
    "Gambela",
    "SNNPR",
    "Dire Dawa",
    "Harari",
  ];

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, loyaltyAgreement: e.target.checked });
  };

  const handleFileUpload = (field, fileInfo) => {
    console.log(uploadedFiles);
    setUploadedFiles({
      ...uploadedFiles,
      [field]: {
        id: fileInfo.id,
        path: fileInfo.path,
      },
    });
  };
  const validateForm = () => {
    const requiredFields = [
      "shopName",
      "contactPhone",
      "openingHours",
   
      "region",
      // "typeOfBusiness",
      // "tinNumber",
    ];
    for (const field of requiredFields) {
      if (!formData[field]) return false;
    }
    if (!formData.loyaltyAgreement) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      alert("Please fill all required fields and agree to loyalty terms.");
      return;
    }

    setLoading(true);

    const token = localStorage.getItem("authToken");
    const requestData = {
      ...formData,
      businessLicense: uploadedFiles.businessLicense.path,
      nationalId: uploadedFiles.nationalId.path,
    };
    try {
      if (initialData) {
        // Update existing shop
        await apiRequest(
          "PUT",
          `/api/v1/zenbil-shops/${initialData.id}`,
          requestData,
          null,
          { Authorization: `Bearer ${token}` }
        );
        alert("Shop updated successfully!");
      } else {
        // Create new shop
        await apiRequest(
          "POST",
          `/api/v1/zenbil-s-hop-companies`,
          requestData,
          null,
          { Authorization: `Bearer ${token}` }
        );
        setLoading(false);
      }
      setOpen(false);
    } catch (error) {
      console.error("Error saving shop:", error);
      alert("Failed to save shop details.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 800, margin: "auto", padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        {initialData ? "Edit Shop Details" : "Register New Shop"}
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              label="Shop Name"
              name="shopName"
              fullWidth
              value={formData.shopName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Owner/Manager's Name"
              name="ownerName"
              fullWidth
              value={formData.ownerName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Phone"
              name="contactPhone"
              fullWidth
              value={formData.contactPhone}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              name="contactEmail"
              fullWidth
              value={formData.contactEmail}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Business Registration Number"
              name="businessRegistrationNumber"
              fullWidth
              value={formData.businessRegistrationNumber}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Opening Hours"
              name="openingHours"
              fullWidth
              value={formData.openingHours}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Address"
              name="address"
              fullWidth
              value={formData.address}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <NativeSelect
              name="region"
              value={formData.region}
              fullWidth
              onChange={handleInputChange}
            >
              {regions.map((region) => (
                <option key={region} value={region}>
                  {region}
                </option>
              ))}
            </NativeSelect>
          </Grid>
          <Grid item xs={12}>
            <NativeSelect
              required
              name="typeOfBusiness"
              value={formData.typeOfBusiness}
              fullWidth
              onChange={handleInputChange}
            >
              <option value="Shop">Shop</option>
              <option value="Supermarket">Supermarket</option>
              <option value="Wholesale">Wholesale</option>
              <option value="Pharmacy">Pharmacy</option>
              <option value="Cafe">Cafe</option>
            </NativeSelect>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="TIN Number"
              name="tinNumber"
              fullWidth
              value={formData.tinNumber}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.loyaltyAgreement}
                  onChange={handleCheckboxChange}
                />
              }
              label="I agree to the loyalty or partnership terms"
            />
          </Grid>
          <Grid item xs={12}>
          <ImageUpload
              label="Upload Business License"
              name="businessLicense"
              onUpload={handleFileUpload}
            />
          </Grid>
          <Grid item xs={12}>
          <ImageUpload
              label="Upload National ID/Fayda ID"
              name="nationalId"
              onUpload={handleFileUpload}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Save"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

ShopRegistrationForm.propTypes = {
  initialData: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
};

export default ShopRegistrationForm;
