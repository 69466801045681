import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';

// eslint-disable-next-line react/prop-types
const MapComponent = ({ onLocationSelect }) => {
  const [position, setPosition] = useState(null);

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        setPosition(e.latlng);
        onLocationSelect(e.latlng);
      },
    });

    return position === null ? null : (
      <Marker position={position} />
    );
  };

  return (
    <MapContainer center={[51.505, -0.09]} zoom={13} style={{ height: '400px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LocationMarker />
    </MapContainer>
  );
};

// Usage inside CreateCompany
<MapComponent onLocationSelect={(latlng) => {
  setCompany({ ...company, latitude: latlng.lat, longitude: latlng.lng });
}} />
