import React from 'react';
import { Navigate } from 'react-router-dom';

// The PrivateRoute component to protect routes
// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ element }) => {
  const authToken = localStorage.getItem("authToken");

  // If the user is not authenticated, redirect to the sign-in page
  return authToken ? element : <Navigate to="/authentication/sign-in" />;
};

export default PrivateRoute;
