import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Paper,
  Box,
  CircularProgress,
  IconButton,
  Grid,
  Dialog,
  DialogContent,
  Button,
} from '@mui/material';
import { ArrowBack, Fullscreen } from '@mui/icons-material';
import axios from 'axios';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { apiRequest } from 'utils/apiRequest';
import ElsabiLoanForm from 'components/ElsabiLoanForm';

const CompanyDetails = () => {
  const [open, setOpen] = useState(false); // Modal open state for add/edit
  const { id } = useParams(); // Extract the ID from the URL
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const navigate = useNavigate();

  // Fetch company details based on the ID
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      const token = localStorage.getItem("authToken");
      const endpoint = `/api/v1/zenbil-s-hop-companies/${id}`;
      try {
        const response = await apiRequest('GET', endpoint, null, null, {
          Authorization: `Bearer ${token}`,
          accept: 'application/json',
        });

        setCompany(response);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching company details:', error);
        setLoading(false);
      }
    };

    fetchCompanyDetails();
  }, [id]);

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleImageClick = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const handleCloseFullscreen = () => {
    setFullscreenImage(null);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!company) {
    return <Typography variant="h6">Company not found</Typography>;
  }

  const openLoanForm = (company = {}) => {
  //  setFormValues(company);
    //setEditingId(company.id || null);
    setOpen(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Paper sx={{ maxWidth: '800px', margin: 'auto', padding: 3 }}>
        <IconButton onClick={handleBackClick} color="primary" sx={{ mb: 2 }}>
          <ArrowBack />
        </IconButton>
        <Button onClick={() => openLoanForm()} variant="contained" color="primary" type="submit" >
              Create Loan
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
            <ElsabiLoanForm setOpen={setOpen}/>

            </Dialog>
        <Typography variant="h6" gutterBottom>
          Basic Information
        </Typography>

        {/* Key-Value Table using Grid */}
        <Grid container spacing={2}>
          {/* Each row consists of two Grid items (key and value) */}
          <Grid item xs={6}><strong>Company Name:</strong></Grid>
          <Grid item xs={6}>{company.shopName}</Grid>
          <Grid item xs={6}><strong>Owner/Manager:</strong></Grid>
          <Grid item xs={6}>{company.ownerName}</Grid>

          <Grid item xs={6}><strong>Contact Info:</strong></Grid>
          <Grid item xs={6}>{company.contactPhone}</Grid>

          <Grid item xs={6}><strong>Contact Email:</strong></Grid>
          <Grid item xs={6}>{company.contactEmail}</Grid>

          <Grid item xs={6}><strong>Business Registration Number:</strong></Grid>
          <Grid item xs={6}>{company.businessRegistrationNumber}</Grid>

          <Grid item xs={6}><strong>Opening Hours:</strong></Grid>
          <Grid item xs={6}>{company.openingHours}</Grid>

          <Grid item xs={6}><strong>Address:</strong></Grid>
          <Grid item xs={6}>{company.address}</Grid>

          <Grid item xs={6}><strong>Region:</strong></Grid>
          <Grid item xs={6}>{company.region}</Grid>

          <Grid item xs={6}><strong>Business Size:</strong></Grid>
          <Grid item xs={6}>{company.businessSize || 'N/A'}</Grid>

          <Grid item xs={6}><strong>Type of Business:</strong></Grid>
          <Grid item xs={6}>{company.typeOfBusiness}</Grid>

          <Grid item xs={6}><strong>Created At:</strong></Grid>
          <Grid item xs={6}>{new Date(company.createdAt).toLocaleString()}</Grid>

          <Grid item xs={6}><strong>Updated At:</strong></Grid>
          <Grid item xs={6}>{new Date(company.updatedAt).toLocaleString()}</Grid>
        </Grid>

        <Typography variant="h6" sx={{ mt: 2 }}>
          Uploaded Documents
        </Typography>

        {/* Display Proof of Address and Business License */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1">Proof of Address</Typography>
            <Box
              component="img"
              src={company.proofOfAddress}
              alt="Proof of Address"
              sx={{ width: '100%', cursor: 'pointer' }}
              onClick={() => handleImageClick(company.proofOfAddress)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1">Business License</Typography>
            <Box
              component="img"
              src={company.businessLicense}
              alt="Business License"
              sx={{ width: '100%', cursor: 'pointer' }}
              onClick={() => handleImageClick(company.businessLicense)}
            />
          </Grid>
        </Grid>
      </Paper>

      {/* Fullscreen Image Viewer */}
      <Dialog open={Boolean(fullscreenImage)} onClose={handleCloseFullscreen} maxWidth="lg">
        <DialogContent>
          <Box sx={{ position: 'relative' }}>
            <Box
              component="img"
              src={fullscreenImage}
              alt="Fullscreen View"
              sx={{ width: '100%', height: 'auto' }}
            />
            <IconButton
              color="secondary"
              onClick={handleCloseFullscreen}
              sx={{ position: 'absolute', top: 10, right: 10 }}
            >
              <Fullscreen />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
};

export default CompanyDetails;
