
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import VirtualReality from "layouts/virtual-reality";
import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Settings from "examples/Icons/Settings";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import Cube from "examples/Icons/Cube";
import CompaniesTable from "layouts/tables";
import CompanyDetails from "layouts/billing";
import UserManagement from "layouts/users";
import ShopManagementTable from "layouts/tables/shopManagement";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    icon: <Shop size="12px" />,
    component: <UserManagement />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Companies",
    key: "companies",
    route: "/companies",
    icon: <Office size="12px" />,
    component: <ShopManagementTable />,
    noCollapse: true,
  },
  {
    type: "internal",
    name: "Company Details", // You can choose any name (optional since it's not in the sidebar)
    key: "company-details",
    route: "/companies/:id",
    component: <CompanyDetails />,
    noCollapse: true,
  },
  { type: "title", title: "Account Pages", key: "account-pages" },

  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: <SignIn />,
    noCollapse: true,
  },

];

export default routes;
