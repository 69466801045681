import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Avatar } from '@mui/material';
import axios from 'axios';

// eslint-disable-next-line react/prop-types
const ImageUpload = ({label, defaultValue, name,onUpload}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [uploadedFileInfo, setUploadedFileInfo] = useState(null);
   useEffect(()=>{
    console.log(defaultValue)
        setPreviewUrl(defaultValue)
   },[defaultValue])
  const handleImageChange =async (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);

      // Generate a preview URL
      const previewUrl = URL.createObjectURL(file);
      setPreviewUrl(previewUrl);
     
      const formData = new FormData();
      formData.append('file', file);
      const token = localStorage.getItem("authToken"); // Assume token is stored in localStorage

      try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/files/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }).then(res=>{
          console.log(res)
          const { id, path } = res.data.file;

          // Store the file information
          setUploadedFileInfo({ id, path });
       
          // Pass the file info back to the parent component (if needed)
          if (onUpload) {
            onUpload(name,{ id, path });
          }
       
               // Update the file field with both the id and path from the response
            
        });
   // Assuming the response contains the file ID and path
 
      
      } catch (error) {
        console.error('File upload failed:', error);
      }}
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!selectedImage) {
      alert('Please select an image first.');
      return;
    }

    // Handle the form submission, e.g., upload image to server
    const formData = new FormData();
    formData.append('image', selectedImage);

    // Example: Make API request to upload the image
    // apiRequest('POST', '/upload', formData); // Adjust to your actual API

    alert('Image uploaded successfully!');
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setPreviewUrl('');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        padding: 2,
        border: '1px solid #ccc',
        borderRadius: '8px',
      }}
    >
      <Typography variant="subtitle" gutterBottom>
      {label}
      </Typography>

      {previewUrl ? (
        <Box>
          <Avatar
            alt="Image Preview"
            src={previewUrl}
            sx={{ maxWidth: 400, maxHeight: 400 }}
          />
          <Button color="secondary" onClick={handleRemoveImage}>
            Remove Image
          </Button>
        </Box>
      ) : (
        <input
          accept="image/*"
          type="file"
          onChange={handleImageChange}
        />
      )}

    </Box>
  );
};

export default ImageUpload;
