import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Paper,
  IconButton,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

import { apiRequest } from "utils/apiRequest";
import ShopRegistrationForm from "components/CreateShop";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const ShopManagementTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  // Fetch shop data
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await apiRequest("GET", "/api/v1/zenbil-s-hop-companies");
      setData(response.data || []);
    } catch (error) {
      console.error("Error fetching shop data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Delete shop
  const handleDelete = async (id) => {
    const token = localStorage.getItem("authToken");
    try {
      await apiRequest(
        "DELETE",
        `/api/v1/zenbil-shops/${id}`,
        null,
        null,
        { Authorization: `Bearer ${token}` }
      );
      fetchData();
    } catch (error) {
      console.error("Error deleting shop:", error);
    }
  };

  // Open form for edit or create
  const handleOpenForm = (row = null) => {
    setSelectedRow(row);
    setOpenForm(true);
  };

  // Close form and refresh data
  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedRow(null);
    fetchData();
  };

  return (
    <div>
         <DashboardLayout>
      <DashboardNavbar />
    <Paper>
      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: 16 }}
        onClick={() => handleOpenForm()}
      >
        Add New Shop
      </Button>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
          <TableHead fullwidth sx={{ display: 'contents' }}>
              <TableRow>
                <TableCell>Shop Name</TableCell>
                <TableCell>Owner Name</TableCell>
                <TableCell>Contact Phone</TableCell>
                <TableCell>Contact Email</TableCell>
                <TableCell>Region</TableCell>
                <TableCell>Type of Business</TableCell>
                <TableCell>TIN Number</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.shopName}</TableCell>
                  <TableCell>{row.ownerName}</TableCell>
                  <TableCell>{row.contactPhone}</TableCell>
                  <TableCell>{row.contactEmail}</TableCell>
                  <TableCell>{row.region}</TableCell>
                  <TableCell>{row.typeOfBusiness}</TableCell>
                  <TableCell>{row.tinNumber}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleOpenForm(row)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(row.id)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Dialog for Add/Edit */}
      <Dialog open={openForm} onClose={handleCloseForm} maxWidth="md" fullWidth>
        <DialogTitle>
          {selectedRow ? "Edit Shop Details" : "Add New Shop"}
        </DialogTitle>
        <DialogContent>
          <ShopRegistrationForm
            setOpen={setOpenForm}
            initialData={selectedRow}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      </Paper>
      </DashboardLayout>
    </div>
  );
};

export default ShopManagementTable;
