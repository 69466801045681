import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Paper,
  CircularProgress,
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import axios from "axios";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MapComponent from "components/MapComponent";
import YourTableComponent from "./sampletable";
import MapForm from "components/MapComponent";
import ShopRegistrationForm from "components/CreateShop";
import { useNavigate } from "react-router-dom";
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog";
import { apiRequest } from "utils/apiRequest";
import EditCompanyDialog from "components/EditCompanyDialog";

const CompaniesTable = () => {
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate(); // useNavigate hook for navigation
  const [companyToDelete, setCompanyToDelete] = useState(null); 
  const [companyToEdit, setCompanyToEdit] = useState(null); // To store the company selected for editing
  const [editDialogOpen, setEditDialogOpen] = useState(false); // Control edit dialog

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false); // Modal open state for add/edit
  const [mapOpen, setMapOpen] = useState(false); // Modal open state for add/edit
  const [formValues, setFormValues] = useState({}); // Form state
  const [editingId, setEditingId] = useState(null); // To track if we're editing
  const [selectedLocation, setSelectedLocation] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); 
  // Callback function to update the selected location string
  const handleLocationSelect = (locationString) => {
    setSelectedLocation(locationString);
  };

  const handleRowClick = (companyId) => {
   
    navigate(`/companies/${companyId}`);
  };


  const token = localStorage.getItem("authToken"); // Assume token is stored in localStorage

  // Fetch companies (READ)
  const fetchCompanies = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/zenbil-s-hop-companies`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { data } = response.data;
      setCompanies(
        data.map((company) => ({
          id: company.id,
          shopName: company.shopName,
          ownerName: company.ownerName,
          businessRegistrationNumber: company.businessRegistrationNumber,
          typeOfBusiness: company.typeOfBusiness,
          region: company.region,
          contactEmail: company.contactEmail,
          contactPhone: company.contactPhone,
          createdAt: new Date(company.createdAt).toLocaleDateString(),
        }))
      );
    } catch (error) {
      console.error("Error fetching companies", error);
    } finally {
      setLoading(false);
    }
  };

  // Add or Edit a company (CREATE/UPDATE)
  const handleSubmit = async () => {
    
    try {
      if (editingId) {
        // Update
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/zenbil-s-hop-companies/${editingId}`,
          formValues,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        // Create
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/zenbil-s-hop-companies`, formValues, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
      setOpen(false);
      fetchCompanies(); // Refresh the list after adding or editing
    } catch (error) {
      console.error("Error saving company", error);
    }
  };


  // Delete a company (DELETE)
  const handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/zenbil-s-hop-companies/${companyToDelete}`, {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res)=>  {setDeleteDialogOpen(false)
   
      });
     
      fetchCompanies(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting company", error);
    }
  };

  

  const [showMap, setShowMap] = useState(true);
  const handleCloseMap = () => {
    setShowMap(false); // Close the map when the "Close" button is clicked
  };
  
  // Handle input change for the form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  // Open the form modal for adding or editing
  const openForm = (company = {}) => {
    setFormValues(company);
    setEditingId(company.id || null);
    setOpen(true);
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleMapOpen = () => {
    setMapOpen(true);
  };


  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };




  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const handleOpenDeleteDialog = (company) => {
    setCompanyToDelete(company.id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setCompanyToDelete(null); // Reset the selected company after closing the dialog
  };

  // const handleOpenEditDialog = (company) => {
    
  //   setCompanyToEdit(company);
  //   setEditDialogOpen(true);
  // };

  const handleOpenEditDialog = async (company) => {
    const token = localStorage.getItem("authToken");
    const endpoint = `/api/v1/zenbil-s-hop-companies/${company.id}`;
    try {
      const response = await apiRequest('GET', endpoint, null, null, {
        Authorization: `Bearer ${token}`,
        accept: 'application/json',
      });

      setCompanyToEdit(response);
      setEditDialogOpen(true);
    } catch (error) {
      console.error('Error fetching company details:', error);
     
    }
  };
  // Close the edit dialog
  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setCompanyToEdit(null); // Reset the selected company after closing the dialog
  };

  // Handle saving the edited company
  const handleSaveEditedCompany = async (updatedCompany) => {
    const token = localStorage.getItem('authToken');
    const endpoint = `/api/v1/zenbil-s-hop-companies/${companyToEdit.id}`;
    // try {
    //   const response = await axios.put(
    //     `http://localhost:3000/api/v1/zenbil-s-hop-companies/${companyToEdit.id}`,
    //     updatedCompany,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    //   // Update the company in the table
    //   setCompanies(companies.map((company) =>
    //     company.id === companyToEdit.id ? { ...company, ...response.data } : company
    //   ));
    //   handleCloseEditDialog(); // Close the dialog after saving
    //   alert('Company updated successfully');
    // } catch (error) {
    //   console.error('Error updating company:', error);
    // }
    try {
      const response = await apiRequest(
        'PATCH', // Method
        endpoint, // Endpoint
        updatedCompany, // Data (body)
        null, // Params (null for this request)
        {
          'Authorization': `Bearer ${token}`, // Authorization header
          'Content-Type': 'application/json', // Content type
        }
      ); 
      
      // Handle the successful response
      console.log('Company updated successfully', response);
    } catch (error) {
      // Handle error, log the response or message
      console.error('Error updating company', error.response?.data || error.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
    <Paper>
      <Typography variant="h6" align="center" sx={{ my: 2 }}>
        List of Companies
      </Typography>
      <Button variant="contained" color="primary" onClick={() => openForm()}>
        Add Company
      </Button>
      <TableContainer component={Paper}>
        <Table >
          <TableHead fullwidth sx={{ display: 'contents' }}>
            <TableRow>
              <TableCell>Shop Name</TableCell>
              <TableCell>Owner Name</TableCell>
            
              <TableCell>Contact Phone</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((company) => (
                <TableRow  
                  hover
                key={company.id}
                onClick={() => handleRowClick(company.id)} // Navigate to details page
                style={{ cursor: 'pointer' }}>
                  <TableCell sx={{ width: '20%' }}>{company.shopName}</TableCell>
                  <TableCell>{company.ownerName}</TableCell>
                 
                  <TableCell>{company.contactPhone}</TableCell>
                  <TableCell>
                <IconButton color="primary" onClick={(e) => {e.stopPropagation(); handleOpenEditDialog(company)}}>
                  <Edit />
                </IconButton>
                <IconButton
                  color="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenDeleteDialog(company);
                  }}
                >
                  <Delete />
                </IconButton>
              </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        component="div"
        count={companies.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}

      {/* Add/Edit Company Modal */}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
  {/* <DialogTitle>{editingId ? "Edit Company" : "Add Company"}</DialogTitle> */}
      {/* <CreateCompany/> */}
      <ShopRegistrationForm setOpen={setOpen}/>
      </Dialog> 
      {/* <YourTableComponent/> */}

    </Paper>
       {/* Delete Confirmation Dialog */}
      {/* Delete Confirmation Dialog */}
      {companyToDelete && (
        <DeleteConfirmationDialog
          open={deleteDialogOpen}
          handleClose={handleCloseDeleteDialog}
          handleConfirm={handleDelete}
        />
      )}

       {/* Edit Company Dialog */}
       <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} maxWidth="sm" fullWidth>
        <EditCompanyDialog
          open={editDialogOpen}
          handleClose={handleCloseEditDialog}
          shopId={companyToEdit?.id}
          handleSave={handleSaveEditedCompany}
        />
      </Dialog>
       </DashboardLayout>
  );
};

export default CompaniesTable;
