import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

function LocationInput() {
  const [location, setLocation] = useState(null);

  const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;
    setLocation(`${lat}, ${lng}`);
  };

  return (
    <div>
      <TextField
        fullWidth
        label="Location"
        value={location || ''}
        onChange={() => {}} // Prevent manual input
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setLocation(null)}>
                <LocationOnIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {location && (
        <MapContainer center={location.split(',').map(Number)} zoom={13} style={{ height: '300px' }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={location.split(',').map(Number)}>
            <Popup>{location}</Popup>
          </Marker>
        </MapContainer>
      )}
    </div>
  );
}

export default LocationInput;