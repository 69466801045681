import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Assuming you use react-router for navigation

// Create an axios instance
const api = axios.create({
  baseURL: 'http://localhost:3000', // Set your base API URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include auth tokens if needed (optional)
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response, // Return response if successful
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect to login page if 401 Unauthorized error is encountered
      window.location.href = '/authentication/sign-in'; // Replace with your login route
    }
    return Promise.reject(error); // Handle other errors
  }
);

export default api;
