// src/utils/apiRequest.js
import api from './axios'; // Import the axios instance

// Function for making API requests
export const apiRequest = async (method, endpoint, data = null, params = null) => {
  let url  = '';
  try {
    const response = await api({
      method,
      url: endpoint,
      data,
      params,
    });
    return response.data;
  } catch (error) {
    console.error('API request error:', error);
    throw error; // You can further handle or log errors as needed
  }
};
